import * as React from 'react'
import classNames from 'classnames'

import { GuestAssetCategory as GuestAssetCategoryType } from 'types'
import BlueDownCaret from 'images/BlueDownCaret.svg'

import { GuestAssetRow } from './components/GuestAssetRow'
import styles from './GuestAssetCategory.module.scss'
import { getURL } from 'utils/getURL'
import DefaultAssetIcon from 'images/DefaultAssetIcon.svg'

export type Props = {
  assetCategory: GuestAssetCategoryType
  assetCategoryIndex: number
  hasQuantity?: boolean
}

export const GuestAssetCategory = (props: Props): JSX.Element | null => {
  const { assetCategory, assetCategoryIndex } = props
  const { assets, collapsed: collapsedProp, icon, name } = assetCategory

  const [collapsed, setCollapsed] = React.useState(collapsedProp)

  if (assets.length === 0) {
    return null
  }

  return (
    <div className={styles.Category}>
      <button
        aria-controls="category-content"
        aria-expanded={!collapsed}
        aria-label={`${collapsed ? 'Show' : 'Hide'} ${name} assets`}
        className={classNames(styles.CategoryHeader)}
        onClick={() => setCollapsed(!collapsed)}
        type="button"
      >
        <div className={classNames(styles.CategoryInfo)}>
          <img
            data-testid="category-icon"
            className={classNames(styles.Icon)}
            alt={name}
            src={icon ? getURL(icon) : DefaultAssetIcon}
          />
          <h6 className={classNames(styles.CategoryName)}>{name}</h6>
        </div>
        <img
          alt="Caret"
          className={classNames(styles.Arrow, { [styles.Collapsed]: collapsed })}
          src={BlueDownCaret}
        />
      </button>

      <div
        aria-hidden={collapsed}
        className={classNames(styles.CategoryContent, { [styles.Collapsed]: collapsed })}
        data-testid="category-content"
        id="category-content"
      >
        {assetCategory.assets.map((asset, assetIndex) => (
          <GuestAssetRow
            asset={asset}
            assetCategoryIndex={assetCategoryIndex}
            assetIndex={assetIndex}
            key={`asset_${asset.asset_id}`}
          />
        ))}
      </div>
    </div>
  )
}
