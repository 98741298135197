import { GuestSettingsResponse } from 'lib/api/types'
import { createContext, JSX, ReactNode, useState } from 'react'

export type GuestOrder = GuestSettingsResponse

type Props = {
  children: ReactNode
}

export type GuestOrderContextType = {
  guestOrder: GuestOrder | null
  setGuestOrder: React.Dispatch<React.SetStateAction<GuestOrder | null>>
}

const GuestOrderContext = createContext<GuestOrderContextType | undefined>(undefined)

const GuestOrderProvider = ({ children }: Props): JSX.Element => {
  const [guestOrder, setGuestOrder] = useState<GuestOrder | null>(null)

  return (
    <GuestOrderContext.Provider value={{ guestOrder, setGuestOrder }}>
      {children}
    </GuestOrderContext.Provider>
  )
}

export { GuestOrderProvider, GuestOrderContext }
