import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

import { Asset } from 'types'
import { QuantityInput } from 'components/QuantityInput'

import styles from './GuestAssetRow.module.scss'

export type Props = {
  asset: Asset
  assetCategoryIndex: number
  assetIndex: number
}

export const GuestAssetRow = ({ asset, assetCategoryIndex, assetIndex }: Props): JSX.Element => (
  <Row className={styles.Row} data-testid={`asset-${asset.asset_id}`}>
    <Form.Group
      className={styles.AssetRow}
      controlId={`asset_${asset.asset_id}`}
      key={asset.asset_id}
    >
      <Form.Label aria-label={asset.name} className={styles.AssetLabel}>
        <div className="flex-grow-1 text-center">{asset.name}</div>
      </Form.Label>

      <QuantityInput
        id={`asset_${asset.asset_id}`}
        name={`assets[${assetCategoryIndex}][${assetIndex}].quantity`}
      />
    </Form.Group>
  </Row>
)
