import { JSX } from 'react'

type Props = {
  isVersion2?: boolean
}

export const PlusIcon = ({ isVersion2 }: Props): JSX.Element => {
  if (isVersion2) {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <g fill="#231F20" fill-rule="evenodd">
          <path d="M18.568 10.017a8.566 8.566 0 0 0-8.547-8.584 8.569 8.569 0 1 0 8.547 8.584M0 10.01C-.005 4.505 4.504-.006 10.006 0 15.505.006 20 4.504 20 10.001S15.5 19.999 10.004 20C4.507 20 .004 15.505 0 10.01" />
          <path d="M9.499 9.497V6.532c.001-.356.274-.587.609-.52.237.047.393.248.393.516v2.968h.165l2.805.001c.355.001.585.277.517.612-.047.234-.25.39-.52.39H10.5v2.972c0 .21-.085.374-.273.47a.48.48 0 0 1-.504-.028c-.161-.106-.226-.264-.226-.454q.002-1.402.001-2.803v-.157H6.535c-.358-.001-.59-.271-.524-.606.047-.242.25-.396.53-.396z" />
        </g>
      </svg>
    )
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
        <g fill="#03a9d0" fillRule="evenodd">
          <path d="M1.564 10.997c.011 5.192 4.144 9.41 9.394 9.43 5.121.019 9.4-4.087 9.425-9.374.025-5.099-4.036-9.416-9.378-9.439-5.17-.022-9.39 4.093-9.441 9.383M10.972.043C17.01.03 21.946 4.963 21.957 11.02c.01 6.026-4.91 10.952-10.966 10.98C4.967 22.027.009 17.079.001 11.03-.01 4.982 4.91.055 10.971.042" />
          <path d="M5.894 12c-.24 0-.47-.023-.656-.269-.212-.28-.286-.61-.208-.989.083-.398.276-.65.59-.713.087-.017.176-.028.264-.028h10.24c.443 0 .695.196.822.639.182.628-.115 1.274-.619 1.344-.087.012-.176.015-.264.015L11.005 12H5.894z" />
          <path d="M12.028 16.193c0 .24-.023.47-.268.656-.28.211-.61.286-.99.207-.398-.082-.65-.275-.713-.59-.017-.087-.027-.175-.027-.263-.002-3.414-.002-6.827-.001-10.24 0-.443.197-.695.639-.823.628-.18 1.274.116 1.344.62.012.087.016.176.016.264v10.169z" />
        </g>
      </svg>
    )
  }
}
