import { PropsWithChildren, ReactNode } from 'react'
import Col from 'react-bootstrap/Col'
import BootstrapModal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'

import { Button } from 'components/Button'

import styles from './ConfirmationDialog.module.scss'

export type Props = PropsWithChildren<{
  acceptText?: ReactNode
  cancelText?: ReactNode
  disabled: boolean
  isDelete?: boolean
  isVersion2?: boolean
  onAccept: () => void
  onCancel: () => void
  show?: boolean
  title: ReactNode
}>

export const ConfirmationDialog = ({
  acceptText = 'Accept',
  cancelText = 'Cancel',
  children,
  disabled,
  isDelete = false,
  isVersion2 = false,
  onAccept,
  onCancel,
  show,
  title,
}: Props): JSX.Element => {
  return (
    <BootstrapModal centered className={styles.Modal} onHide={onCancel} show={show}>
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>{title}</BootstrapModal.Title>
      </BootstrapModal.Header>

      <BootstrapModal.Body>
        <Container fluid className={styles.Container}>
          {children}
        </Container>
      </BootstrapModal.Body>

      <BootstrapModal.Footer className={styles.Footer}>
        <Row className="justify-content-end w-100">
          <Col xs={6}>
            <Button className="w-100" onClick={onCancel} variant="outline-dark">
              {cancelText}
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              className="w-100"
              disabled={disabled}
              onClick={onAccept}
              isVersion2={isVersion2}
              isDelete={isDelete}
            >
              {disabled ? <Spinner animation="border" as="span" size="sm" /> : acceptText}
            </Button>
          </Col>
        </Row>
      </BootstrapModal.Footer>
    </BootstrapModal>
  )
}
