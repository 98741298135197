import classNames from 'classnames'
import styles from './AssetNumber.module.scss'

type Props = {
  number: number
}

const AssetNumber = ({ number }: Props) => {
  const colorClass = `Circle-${number % 10}`

  return (
    <span className={classNames(styles.Circle, styles[colorClass])} data-testid="circle">
      <span className={styles.Number}>{number}</span>
    </span>
  )
}

export default AssetNumber
