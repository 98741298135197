import * as yup from 'yup'

export const GuestAssetsSchema = yup.object().shape({
  assets: yup
    .array()
    .of(
      yup.array().of(
        yup.object().shape({
          asset_id: yup.number<number>(),
          quantity: yup.number().min(0, 'Quantity cannot be negative.'),
        }),
      ),
    )
    .test('at-least-one-asset', 'You must enter at least one asset.', value => {
      if (!value || value.length === 0) return false
      // @ts-ignore
      return value.some(category => category?.some(asset => asset?.quantity && asset.quantity > 0))
    }),
})
