import * as React from 'react'
import classNames from 'classnames'
import { Field, FieldProps } from 'formik'

import styles from './TextField.module.scss'

type Props = {
  autoComplete?: string
  className?: string
  description?: string
  disabled?: boolean
  hideLabel?: boolean
  hideValidation?: boolean
  label: string
  name: string
  onBlur?: (props: FieldProps) => void
  readOnly?: boolean
  type?: 'email' | 'password' | 'tel' | 'text'
}

export const TextField = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      autoComplete = 'off',
      className,
      description,
      disabled,
      hideLabel,
      hideValidation,
      label,
      name,
      onBlur,
      readOnly,
      type = 'text',
    },
    ref,
  ) => {
    return (
      <Field name={name}>
        {({ field, form, meta }: FieldProps) => {
          const invalid = meta.touched && meta.error && form.submitCount > 0

          return (
            <div className={className}>
              <div className={styles.TextFieldContainer}>
                <input
                  {...field}
                  autoComplete={autoComplete}
                  autoCorrect="off"
                  className={classNames(styles.Input, {
                    [styles.Invalid]: invalid && !hideValidation,
                  })}
                  disabled={disabled}
                  readOnly={readOnly}
                  id={name}
                  placeholder={label}
                  onBlur={e => {
                    field.onBlur(e)

                    if (typeof field.value === 'string') {
                      void form.setFieldValue(name, field.value.trim())
                    }

                    if (onBlur) {
                      onBlur({ field, form, meta })
                    }
                  }}
                  ref={ref}
                  type={type}
                />
                <div className={styles.Notch}>
                  <div className={styles.NotchBefore}></div>
                  <div
                    className={classNames(styles.NotchBetween, { [styles.LabelHidden]: hideLabel })}
                  >
                    <label htmlFor={name}>{label}</label>
                  </div>
                  <div className={styles.NotchAfter}></div>
                </div>
              </div>

              {description && <div className={styles.FieldDescription}>{description}</div>}
              {invalid && !hideValidation && <div className={styles.ErrorText}>{meta.error}</div>}
            </div>
          )
        }}
      </Field>
    )
  },
)

TextField.displayName = 'TextField'
