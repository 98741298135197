import { GuestAssetFormValues } from 'forms/GuestAssetsForm/GuestAssetsForm'
import { History } from 'history'
import { submitAssetsPage } from 'lib/api/guest/submitAssetsPage'
import { GuestAssetQuantity, GuestUserAction } from 'types'

export type GuestAssetsPayload = {
  assets: GuestAssetQuantity[]
  token: string
  user_action: GuestUserAction
}

const handleSubmit = async (formValues: GuestAssetFormValues, token: string, history: History) => {
  const payload: GuestAssetsPayload = {
    assets: formValues.assets.flat(),
    token,
    user_action: GuestUserAction.next,
  }

  const res = await submitAssetsPage(payload)
  history.push(res.page_path)
}

export { handleSubmit }
