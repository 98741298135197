import { TrashIcon } from 'components/TrashIcon'

import styles from './RemoveBoxRowButton.module.scss'

export type RemoveBoxRowProps = {
  index: number
  onClick: () => void
}

export const RemoveBoxRowButton = ({ index, onClick }: RemoveBoxRowProps): JSX.Element => (
  <button
    aria-label={`Remove box ${index + 1}`}
    className={styles.Button}
    onClick={onClick}
    title="Remove box"
    type="button"
  >
    <TrashIcon />
  </button>
)
