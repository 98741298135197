import { WizardPage } from 'components/WizardPage/WizardPage'
import { GuestBoxesForm, emptyBox } from 'forms/GuestBoxesForm/GuestBoxesForm'
import { JSX } from 'react'
import { GuestBox } from 'types'

const GuestBoxesPage = (): JSX.Element => {
  const initialRows: GuestBox[] = [{ ...emptyBox }]

  return (
    <WizardPage>
      <GuestBoxesForm
        initialValues={{ boxes: initialRows }}
        onGoToPreviousPage={() => null}
        onSubmit={() => {}}
        title="Enter details for your boxes"
      />
    </WizardPage>
  )
}

export default GuestBoxesPage
