import { Loader } from 'components/Loader'
import { useGuestOrderContext } from 'hooks/useGuestOrderContext'
import { getSettingsForGuest } from 'lib/api/setting/getSettingsForGuest'
import { InventoryTypeResponse } from 'lib/api/types'
import NotFoundPage from 'pages/NotFoundPage'
import { useEffect, useState } from 'react'
import { Redirect, useLocation, useParams } from 'react-router-dom'

export type GuestIntakeWhoOption = 'business' | 'personal'
export type GuestIntakeWhatOption = InventoryTypeResponse
export type GuestIntakeWhereOption = 'USA' | 'Other'

export type GuestIntakeOptions = {
  path: string
  what: GuestIntakeWhatOption | null
  where: GuestIntakeWhereOption | null
  who: GuestIntakeWhoOption | null
}

const GuestRedirectPage = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isError, setIsError] = useState<boolean>(false)
  const [nextPage, setNextPage] = useState<string | null>(null)
  const { brand_path: brandPath } = useParams<{ brand_path: string }>()
  const location = useLocation()
  const { setGuestOrder } = useGuestOrderContext()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const path = `/${brandPath}/guest`
    const who = searchParams.get('rc-customer') as GuestIntakeWhoOption
    const what = searchParams.get('rc-product') as GuestIntakeWhatOption
    const where = searchParams.get('rc-country') as GuestIntakeWhereOption

    const guestIntakeOptions: GuestIntakeOptions = {
      path,
      what,
      where,
      who,
    }

    getSettingsForGuest(guestIntakeOptions)
      .then(res => {
        setGuestOrder(res)
        setNextPage(res.page_path)
      })
      .catch(() => {
        setIsError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [location.search, brandPath, setGuestOrder])

  if (isLoading) {
    return <Loader />
  } else if (isError) {
    return <NotFoundPage />
  } else if (nextPage) {
    return <Redirect to={nextPage} />
  } else {
    return <h1>Unable to load page</h1>
  }
}

export default GuestRedirectPage
