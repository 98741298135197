import { PlusIcon } from 'components/PlusIcon'

import styles from './AddRowButton.module.scss'

export type AddRowButtonProps = {
  disabled?: boolean
  label: string
  onClick: () => void
}

export const AddRowButton = ({
  disabled = false,
  label,
  onClick,
}: AddRowButtonProps): JSX.Element => (
  <button
    aria-label={label}
    className={styles.Button}
    onClick={onClick}
    type="button"
    disabled={disabled}
  >
    <PlusIcon />
    {label}
  </button>
)
