import { WizardPage } from 'components/WizardPage/WizardPage'
import { GuestAssetsForm } from 'forms/GuestAssetsForm/GuestAssetsForm'
import { JSX } from 'react'
import * as helpers from './helpers'
import { GuestAssetCategory as GuestAssetCategoryType } from 'types'
import { useGuestOrderContext } from 'hooks/useGuestOrderContext'
import { useHistory } from 'react-router-dom'

const GuestAssetsPage = (): JSX.Element => {
  const guestOrderContext = useGuestOrderContext()
  const categories: GuestAssetCategoryType[] =
    guestOrderContext.guestOrder?.inventory_parameters.asset_categories || []
  const token = guestOrderContext.guestOrder?.token || ''
  const history = useHistory()

  const initialValues = categories.map(assetCategory =>
    assetCategory.assets.map(asset => ({
      asset_id: asset.asset_id,
      quantity: 0,
    })),
  )

  return (
    <WizardPage>
      <GuestAssetsForm
        categories={categories}
        initialValues={{ assets: initialValues }}
        onGoToPreviousPage={() => null}
        onSubmit={values => helpers.handleSubmit(values, token, history)}
        title="What are your assets"
      />
    </WizardPage>
  )
}

export default GuestAssetsPage
