import { FormContainer, TPreviousPageHandler } from 'components/FormContainer'
import { Formik } from 'formik'
import { Form } from 'components/Form'

import { JSX } from 'react'
import {
  GuestAssetCategory as GuestAssetCategoryType,
  GuestAssetQuantity,
  TFormSubmit,
} from 'types'
import { GuestAssetCategory } from './components/GuestAssetCategory/GuestAssetCategory'
import { GuestAssetsSchema } from './schema'

export type GuestAssetFormValues = {
  assets: GuestAssetQuantity[][]
}

export type GuestAssetFormProps = {
  categories: GuestAssetCategoryType[]
  initialValues: GuestAssetFormValues
  onGoToPreviousPage: TPreviousPageHandler
  onSubmit: TFormSubmit<GuestAssetFormValues, void>
  title: string
}

export const GuestAssetsForm = (props: GuestAssetFormProps): JSX.Element => {
  const { categories, initialValues, onGoToPreviousPage, onSubmit, title } = props

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={values => onSubmit(values)}
      title={title}
      validationSchema={GuestAssetsSchema}
    >
      {({ errors, handleSubmit, isSubmitting, submitCount }) => (
        <Form onSubmit={handleSubmit}>
          <FormContainer
            formError={
              submitCount > 0 && errors.assets && typeof errors.assets === 'string'
                ? errors.assets
                : ''
            }
            fullWidth
            isSubmitting={isSubmitting}
            isVersion2
            onGoToPreviousPage={onGoToPreviousPage}
            title={title}
          >
            {categories.map((category, i) => (
              <div className="FieldSet" key={i}>
                <GuestAssetCategory assetCategory={category} assetCategoryIndex={i} />
              </div>
            ))}
          </FormContainer>
        </Form>
      )}
    </Formik>
  )
}
