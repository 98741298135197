import { api } from 'lib/api/base'
import { GUEST_ENDPOINTS } from 'lib/api/endpoints'
import { GuestAssetsResponse } from 'lib/api/types'
import { GuestAssetsPayload } from 'pages/OrderPages/pages/GuestAssetsPage/helpers'

export const submitAssetsPage = async (payload: GuestAssetsPayload) => {
  const assets = payload.assets.filter(asset => asset.quantity && asset.quantity > 0)

  const formData = new FormData()
  formData.append('token', payload.token)
  formData.append('assets', JSON.stringify(assets))
  formData.append('user_action', payload.user_action)

  const { data } = await api.post<GuestAssetsResponse>(GUEST_ENDPOINTS.guestAssets, formData)

  return data
}
