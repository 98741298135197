import { Page } from 'components/Page'
import { JSX } from 'react'

const GuestPalletsPage = (): JSX.Element => {
  return (
    <Page>
      <h1>Guest Pallets Page</h1>
    </Page>
  )
}

export default GuestPalletsPage
