import classNames from 'classnames'
import { Field, FieldProps } from 'formik'

import { MinusIcon } from 'components/MinusIcon'
import { PlusIcon } from 'components/PlusIcon'

import styles from './QuantityInput.module.scss'

const numberRegex = /^([1-9]\d*|0)$/ // Allows positive integers or 0

type Props = {
  className?: string
  id?: string
  isVersion2?: boolean
  name: string
}

export const QuantityInput = ({ className, id, isVersion2, name }: Props): JSX.Element => (
  <Field name={name}>
    {({ field, form }: FieldProps<number>) => (
      <div className={classNames(styles.QuantityInputContainer, className)}>
        <button
          aria-label="Decrement quantity"
          className={styles.Button}
          onClick={() => void form.setFieldValue(name, Math.max(0, Number(field.value) - 1))}
          type="button"
        >
          <MinusIcon aria-hidden isVersion2={isVersion2} />
        </button>

        <input
          {...field}
          className={classNames('form-control text-center', {
            [styles.ZeroValue]: field.value === 0,
          })}
          id={id}
          min={0}
          onChange={e => {
            if (!e.target.value || numberRegex.test(e.target.value)) {
              field.onChange(e)
            }
          }}
          placeholder="0"
          type="number"
        />

        <button
          aria-label="Increment quantity"
          className={styles.Button}
          onClick={() => void form.setFieldValue(name, Math.max(0, Number(field.value) + 1))}
          type="button"
        >
          <PlusIcon aria-hidden isVersion2={isVersion2} />
        </button>
      </div>
    )}
  </Field>
)
